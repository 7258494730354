import React from "react"
import styled from 'styled-components';

const Box = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: 30px auto;
    padding: 20px;
    background-color: var(--neutral-100);
    /* border: 1px solid var(--neutral-400); */
    box-shadow: var(--shadow-b);
    h2, h3 {
    color: var(--accent-orange-600);
    }
    p {
        font-size: 18px;
    }

    @media only screen and (min-width: 1000px) {
        padding: 20px 30px;
    }
`;

const StyledList = styled.ul`
    list-style: square;
    padding-left: 5%;
    li {
        font-size: 18px;
        margin-bottom: 10px;
    }

    li::marker {
        color: var(--accent-orange-600);
    }

    @media only screen and (min-width: 1000px) {
        columns: 2;

        li {
            padding-right: 20px;
        }
    }
`;


const  BulletBox = (props) => (
    <Box>
        <h2>{props.heading}</h2>
        <p>{props.paragraph}</p>
        <StyledList>
            {props.list.map(item => <li>{item}</li>)}
        </StyledList>
    </Box>
);

export default BulletBox;
import React from "react"
import styled from "styled-components"
import BulletBox from "../components/bulletBox/bulletBox"
import Layout from "../components/layout/layout"
import Title from "../components/title/title"
import Form from "../components/form/form"
import { Helmet } from "react-helmet"

const Section = styled.section`
  padding-bottom: 20px;
`;

const Box = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: 30px auto;
  padding: 20px;
  background-color: var(--neutral-100);
  /* border: 1px solid var(--neutral-400); */
  box-shadow: var(--shadow-b);
  h2, h3 {
    color: var(--accent-orange-600);
  }
  h3 {
    font-size: 18px;
  }
  @media only screen and (min-width: 1000px) {
    padding: 20px 30px;
    }
`;

const ColumnContainer = styled.div`
  display: block;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 25px;
  p {
    font-size: 18px;
  }
  @media only screen and (min-width: 1000px) {
    display: grid;
    }
`;


export default function Services() {
  const bulletBox1List = [
    "Electrical performance estimates",
    "Solar thermal performance estimates",
    "Heat pump performance estimates",
    "System energy savings",
    "System revenue generation",
    "ROI calculation",
    "Annual and cumulative benefit graphs",
    "Payback tables",
    "Predicted CO2 offsets"
  ];
  const bulletBox2List = [
    "Roof layout design for PV-T panels",
    "Hydraulic and electrical schematics",
    "Foundation level Earth Energy Bank layout",
    "DC schematics for the PV-T array",
    "Heat emitter sizing per room",
    "Plant room layout plan (if required)",
    "Full component specifications",
    "All brochures and manuals"
  ]
  const bulletBox3List = [
    "All PV-T panels are provided with a 10 year product guarantee and 20 year performance warranty.",
    "Each heat pump comes with a remote monitoring and diagnostics service, along with a 7 year parts and labour guarantee and 10 year warranty for the compressor.",
    "Earth Energy Bank installation is pressurised and tested to 5 times the working system pressure and a certificate is provided to each customer certifying that this standard. The PE100 pipe used is extremely robust and chemically inert. It is commonly used in the geothermal industry and can be expected to last the full lifetime of the house."
  ]

  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Services | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <Title
        title="Services"
        subtitle="All aspects of our system are the responsibility of a single organisation. We provide a complete service from the original financial analysis and appraisal, through supply, to commissioning and post-installation​ field support."
      />
      <Section>
        <BulletBox
          heading="Appraisal"
          paragraph="We will ask you for a set of drawings that include roof and foundations layouts, fabric U values, building orientation and location. From this information,​ we will be able to provide you with a package the contains ​all of the following data:"
          list={bulletBox1List}
        />
        <BulletBox
          heading="Design"
          paragraph="We can then develop a complete bespoke system design package for your build. This will include:"
          list={bulletBox2List}
        />
        <Box>
          <h2>Installation</h2>
          <p>The system has three stages of installation.</p>
          <ColumnContainer>
            <div>
              <h3>Earth Energy Bank is installed</h3>
              <p>This stage takes place after the trench foundation have been filled and during the building of the blockwork. For a standard sized house, the work will usually be completed in one or two days with hydraulic lines prepared and left in position ready for later connection to the heat pump.</p>
            </div>
            <div>
              <h3>PV-T panels are mounted</h3>
              <p>Panel installation can begin when the roof is ready for covering. First the Renusol in-roof mounting will be fixed, then the panels installed and run through to the plant room location. During spring or summer, the system will be filled and a solar pump station and control will be provided, so charging of the EEB can begin for the coming winter.</p>
            </div>
            <div>
              <h3>Heat pump is fitted</h3>
              <p>The GSHP can be installed when mains services have been connected. The DC/AC inverter will be installed and all plumbing and electrical work carried out before commissioning of the heat pump by an engineer. Customers will be instructed in the use of the system and presented with a handover pack.</p>
            </div>
          </ColumnContainer>
        </Box>
        <BulletBox
          heading="Support &amp; Waranties"
          paragraph="The entire system is backed by an industry leading warranty and support service. The main features are:"
          list={bulletBox3List}
        />
      </Section>
      <section style={{backgroundColor: "var(--accent-turquoise-400)"}}>
        <Form headingColor="white" />
      </section>
    </Layout>
  )
}
